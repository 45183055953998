import React, { useState, useEffect, useCallback } from 'react';
import './SettingsPage.css'; // We'll create this CSS file for our styles
import { useAuth } from '../../auth';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { auth } from '../../firebase';


import { Country, State } from 'country-state-city';
import { currencyCodesSymbols } from '../../utils/currencyUtils';
import { companySizeOptions, industryOptions } from '../../utils/settings_utils';
import Snackbar from '../../components/Snackbar/Snackbar';

const tabs = [
  'My details',
  'Organization',
  'Password',
  'Team',
  'Plan',
  'Billing',
  'Notifications',
  'Integrations'
];


const FormItem = ({ label, description, children, required }) => (
    <div className="form-item">
      <div className="form-item-label">
        <label>{label}{required && <span className="required">*</span>}</label>
        {description && <p className="form-item-description">{description}</p>}
      </div>
      <div className="form-item-input">
        {children}
      </div>
    </div>
  );
  
  const SettingsSection = ({ title, subtitle, children }) => (
    <div className="settings-section">
      <h2>{title}</h2>
      {subtitle && <p className="section-subtitle">{subtitle}</p>}
      {children}
    </div>
  );


const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState('My details');
    const [userDetails, setUserDetails] = useState({
      firstName: '',
      lastName: '',
      email: ''
    });

    const [organizationDetails, setOrganizationDetails] = useState({
      name: '',
      url: '',
      industry: '',
      size: '',
      email: '',
      taxNumber: '',
      address: {
        street1: '',
        street2: '',
        country: '',
        state: '',
        city: '',
        zipCode: ''
      },
      currency: '',
      logo: ''
    });

    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success'
    });
  
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const BACKEND_API_URL= process.env.REACT_APP_BACKEND_API_URL;

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const { user } = useAuth();
    const { getIdToken } = useAuth();

    const isPasswordProvider = user?.providerData.some(
      (provider) => provider.providerId === 'password'
    );
  
    const handlePasswordChange = async (e) => {
      e.preventDefault();
      if (newPassword !== confirmNewPassword) {
        setSnackbar({
          open: true,
          message: 'New passwords do not match',
          severity: 'error',
        });
        return;
      }
  
      try {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(auth.currentUser, newPassword);
        setSnackbar({
          open: true,
          message: 'Password updated successfully',
          severity: 'success',
        });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } catch (error) {
        console.error('Error updating password:', error);
        setSnackbar({
          open: true,
          message: 'Failed to update password. Please check your current password and try again.',
          severity: 'error',
        });
      }
    };
  
    const renderPasswordSection = () => {
      if (!isPasswordProvider) {
        return (
          <p>You're signed in with Google. Password management is not available.</p>
        );
      }
  
      return (
        <form onSubmit={handlePasswordChange}>
          <SettingsSection title="Password" subtitle="Update your password here.">

          </SettingsSection>
          <FormItem label="Current Password" required>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </FormItem>
          <FormItem label="New Password" required>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </FormItem>
          <FormItem label="Confirm New Password" required>
            <input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
          </FormItem>
          <button type="submit" className="btn-save">
            Update Password
          </button>
        </form>
      );
    };



    const fetchUserDetails = useCallback(async () => {
      console.log('Fetching user details:', user);
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        console.log('Token retrieved successfully');
    
        const response = await fetch(`${BACKEND_API_URL}/api/settings/my-details`, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
    
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log('Received data:', data);
    
        setUserDetails(data);
      } catch (err) {
        console.error('Error in fetchUserDetails:', err);
        setError('Failed to fetch user details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, [user, BACKEND_API_URL, getIdToken]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name !== 'email') {
        setUserDetails(prev => ({ ...prev, [name]: value }));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/my-details`, {
          method: 'PUT',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userDetails)
        });
        if (!response.ok) {
          throw new Error('Failed to update user details');
        }
        const data = await response.json();
        setUserDetails(data);
        setSnackbar({
          open: true,
          message: 'Details updated successfully!',
          severity: 'success'
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to update user details. Please try again.',
          severity: 'error'
        });
        console.error('Error updating user details:', err);
      } finally {
        setIsLoading(false);
      }
    };
  
    const renderMyDetails = () => (
      <SettingsSection title="My details" subtitle="Update your details here.">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormItem label="First name" description="This will be displayed on your profile." required>
              <input
                type="text"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleInputChange}
                required
              />
            </FormItem>
            <FormItem label="Last name" description="This will be displayed on your profile." required>
              <input
                type="text"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleInputChange}
                required
              />
            </FormItem>
            <FormItem label="Personal email" description="This will be displayed on your profile." required>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                required
                disabled
                className='disabled-input'
              />
            </FormItem>
            <div className="button-group">
              <button type="button" className="btn-cancel" onClick={() => fetchUserDetails()}>Cancel</button>
              <button type="submit" className="btn-save" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        )}
      </SettingsSection>
    );
    

    const fetchOrganizationDetails = useCallback(async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/organization`, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch organization details');
        }
        const data = await response.json();
        setOrganizationDetails(data);
      } catch (err) {
        setError('Failed to fetch organization details. Please try again.');
        console.error('Error fetching organization details:', err);
      } finally {
        setIsLoading(false);
      }
    }, [BACKEND_API_URL, getIdToken]);
  
    const handleOrganizationInputChange = useCallback((e) => {
      const { name, value } = e.target;
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        setOrganizationDetails(prev => {
          if (parent === 'address' && child === 'country') {
            // Reset state when country changes
            return {
              ...prev,
              address: { ...prev.address, [child]: value, state: '' }
            };
          }
          return {
            ...prev,
            [parent]: { ...prev[parent], [child]: value }
          };
        });
      } else {
        setOrganizationDetails(prev => ({ ...prev, [name]: value }));
      }
    }, []);

    useEffect(() => {
      if (user) {
        fetchUserDetails();
        fetchOrganizationDetails();
      }
    }, [user, fetchOrganizationDetails, fetchUserDetails]);


  
    const handleOrganizationSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      try {
        const token = await getIdToken();
        const response = await fetch(`${BACKEND_API_URL}/api/settings/organization`, {
          method: 'PUT',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(organizationDetails)
        });
        if (!response.ok) {
          throw new Error('Failed to update organization details');
        }
        const data = await response.json();
        setOrganizationDetails(data);
        setSnackbar({
          open: true,
          message: 'Organization details updated successfully!',
          severity: 'success'
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to update organization details. Please try again.',
          severity: 'error'
        });
        console.error('Error updating organization details:', err);
      } finally {
        setIsLoading(false);
      }
    };

    const handleLogoUpload = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setIsLoading(true);
        try {
          const formData = new FormData();
          formData.append('logo', file);
  
          const token = await getIdToken();
          const response = await fetch(`${BACKEND_API_URL}/api/settings/organization/logo`, {
            method: 'POST',
            headers: { 
              'Authorization': `Bearer ${token}`
            },
            body: formData
          });
  
          if (!response.ok) {
            throw new Error('Failed to upload logo');
          }
  
          const data = await response.json();
          setOrganizationDetails(prev => ({ ...prev, logo: data.logoUrl }));
          setSnackbar({
            open: true,
            message: 'Logo uploaded successfully!',
            severity: 'success'
          });
        } catch (err) {
          setSnackbar({
            open: true,
            message: 'Failed to upload logo. Please try again.',
            severity: 'error'
          });
          console.error('Error uploading logo:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    const renderOrganization = () => (
      <SettingsSection title="Company profile" subtitle="Update your company profile and details here.">
        <form onSubmit={handleOrganizationSubmit}>
          <FormItem label="Company name" description="This will be displayed on your profile." required>
            <input
              type="text"
              name="name"
              value={organizationDetails.name}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          <FormItem label="Company Website" description="This will be displayed on your profile." required>
            <input
              type="url"
              name="url"
              value={organizationDetails.url}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          <FormItem label="Industry" description="This will be displayed on your profile." required>
            <select
              name="industry"
              value={organizationDetails.industry}
              onChange={handleOrganizationInputChange}
              required
            >
              <option value="" disabled>Select an industry</option>
              {industryOptions.map((industry) => (
                <option key={industry.value} value={industry.value}>
                  {industry.label}
                </option>
              ))}
            </select>
          </FormItem>
          <FormItem label="Company Size" description="This will be displayed on your profile." required>
            <select
              name="size"
              value={organizationDetails.size}
              onChange={handleOrganizationInputChange}
              required
            >
              <option value="" disabled>Select company size</option>
              {companySizeOptions.map((size) => (
                <option key={size.value} value={size.value}>
                  {size.label}
                </option>
              ))}
            </select>
          </FormItem>
          <FormItem label="Primary Contact Email" description="This will be used for important communications." required>
            <input
              type="email"
              name="email"
              value={organizationDetails.email}
              onChange={handleOrganizationInputChange}
              required
            />
          </FormItem>
          <FormItem label="VAT Number" description="Your company's VAT identification number.">
            <input
              type="text"
              name="taxNumber"
              value={organizationDetails.taxNumber}
              onChange={handleOrganizationInputChange}
            />
          </FormItem>
          <FormItem label="Company Address" description="Your company's official address.">
            <div className="form-item-input">
              <input
                type="text"
                name="address.street1"
                value={organizationDetails.address.street1}
                onChange={handleOrganizationInputChange}
                placeholder="Street Address 1"
              />
              <input
                type="text"
                name="address.street2"
                value={organizationDetails.address.street2}
                onChange={handleOrganizationInputChange}
                placeholder="Street Address 2"
              />
              <select
                name="address.country"
                value={organizationDetails.address.country}
                onChange={handleOrganizationInputChange}
              >
                <option value="" disabled>Select Country</option>
                {Country.getAllCountries().map((country, index) => (
                  <option key={`${country.isoCode}-${index}`} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
              <select
                name="address.state"
                value={organizationDetails.address.state}
                onChange={handleOrganizationInputChange}
              >
                <option value="" disabled>Select State/Province</option>
                {State.getStatesOfCountry(organizationDetails.address.country).map((state, index) => (
                  <option key={`${state.isoCode}-${index}`} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="address.city"
                value={organizationDetails.address.city}
                onChange={handleOrganizationInputChange}
                placeholder="City"
              />
              <input
                type="text"
                name="address.zipCode"
                value={organizationDetails.address.zipCode}
                onChange={handleOrganizationInputChange}
                placeholder="Zip Code"
              />
            </div>
          </FormItem>
          <FormItem label="Base Currency" description="Your company's primary operating currency." required>
            <select
              name="currency"
              value={organizationDetails.currency}
              onChange={handleOrganizationInputChange}
              required
            >
              <option value="" disabled>Select Currency</option>
              {currencyCodesSymbols.map((currency, index) => (
                <option key={`${currency.code}-${index}`} value={currency.code}>
                  {currency.code} ({currency.symbol})
                </option>
              ))}
            </select>
          </FormItem>
          <FormItem label="Company Logo" description="Upload your company logo.">
            {organizationDetails.logo && (
              <img src={organizationDetails.logo} alt="Company Logo" className="company-logo-preview" />
            )}
            <input 
              type="file" 
              name="logo" 
              onChange={handleLogoUpload} 
              accept="image/*"
            />
          </FormItem>
          <div className="button-group">
            <button type="button" className="btn-cancel" onClick={() => fetchOrganizationDetails()}>Cancel</button>
            <button type="submit" className="btn-save" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </SettingsSection>
    );

    



    const renderTabContent = () => {
      switch (activeTab) {
        case 'My details':
          return renderMyDetails()
        case 'Organization':
          return renderOrganization()
        case 'Password':
          return renderPasswordSection()
        // Add cases for other tabs here
        default:
          return <div>Content for {activeTab}</div>;
      }
    };

    return (
      <div className="settings-page">
        <h1 className="settings-title">Settings</h1>
        <div className="tabs-container">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'tab-active' : 'tab-inactive'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {renderTabContent()}
        <Snackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
      </div>
    );
  };

  export default SettingsPage;
