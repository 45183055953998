import React from "react";
import { useLocation } from 'react-router-dom';

import PropTypes from "prop-types";
import "./HeaderComponent.css";
import addIcon from "../../assets/icons/Add.svg"; // Adjust the path as needed

const HeaderComponent = ({ className = "", onAddClick, pageType, entityType }) => {

    const location = useLocation();
    const path = location.pathname.slice(1); // Remove leading slash

    // Function to match the base route
    const matchRoute = (route) => {
      const regex = new RegExp(`^${route}(/.*)?$`);
      return regex.test(path);
  };

    let mainTitle = '';
    let subTitle = '';
    let subtitle = '';
    let buttonText = '';

    if (matchRoute('dashboard')) {
      subTitle = 'Dashboard';
      subtitle = 'Overview of your financial data.';
      buttonText = 'Add new invoice';
  } else if (matchRoute('expense-invoices')) {
      mainTitle = 'Expenses /';
      subTitle = 'Invoices';
      subtitle = 'Detailed view of your expenses.';
      buttonText = 'Add new expense invoice';
  } else if (matchRoute('suppliers')) {
      mainTitle = 'Expenses /';
      subTitle = 'Suppliers';
      subtitle = 'Detailed view of your suppliers.';
      buttonText = 'Add new supplier';
  } else if (matchRoute('supplier-categories')) {
      mainTitle = 'Expenses /';
      subTitle = 'Categories';
      subtitle = 'Detailed view of your supplier categories.';
      buttonText = 'Add new category';
  } else if (matchRoute('revenue-invoices')) {
      mainTitle = 'Revenue /';
      subTitle = 'Invoices';
      subtitle = 'Detailed view of your revenue.';
      buttonText = 'Add new revenue invoice';
  } else if (matchRoute('customers')) {
      mainTitle = 'Revenue /';
      subTitle = 'Customers';
      subtitle = 'Detailed view of your customers.';
      buttonText = 'Add new customer';
  } else if (matchRoute('customer-categories')) {
      mainTitle = 'Revenue /';
      subTitle = 'Categories';
      subtitle = 'Detailed view of your customer categories.';
      buttonText = 'Add new category';
  } else {
      subTitle = 'Unknown Page';
      subtitle = 'Page not found.';
      buttonText = 'Add new';
  }

  return (
    <div className={`header-container ${className}`}>
      <div className="text-container">
        <div className="title">
          {mainTitle && <span className="main-title">{mainTitle}</span>}
          <span className="sub-title">{subTitle}</span>
        </div>
        <div className="subtitle">
          <span>{`${subtitle} `}</span>
          <span className="help-page">Help Page</span>
          <span>.</span>
        </div>
      </div>
      <div className="button-container" onClick={onAddClick}>
        <div className="button-icon">
          <img className="add-icon" alt="" src={addIcon} />
        </div>
        <div className="button-text">{buttonText}</div>
      </div>
    </div>
  );
};

HeaderComponent.propTypes = {
  className: PropTypes.string,
  onAddClick: PropTypes.func.isRequired,
};

export default HeaderComponent;
