import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import SignUp from "./components/Authentication/Signup/Signup";
import SignIn from "./components/Authentication/SignIn/SignIn"; 
import { useAuth, AuthProvider } from "./auth";
import Dashboard from "./components/Dashboard/Dashboard";
import Layout from "./components/Layout/Layout";
import RevenueInvoicesPage from "./pages/RevenueInvoicesPage/RevenueInvoicesPage";
import ExpenseInvoicesPage from "./pages/ExpenseInvoicesPage/ExpenseInvoicesPage";
import SuppliersPage from "./pages/SuppliersPage/SuppliersPage";
import CustomersPage from "./pages/CustomersPage/CustomersPage";
import SupplierCategoriesPage from "./pages/SupplierCategoriesPage/SupplierCategoriesPage";
import CustomerCategoriesPage from "./pages/CustomerCategoriesPage/CustomerCategoriesPage";
import Onboarding from "./components/Onboarding/Onboarding";
import Error404 from "./pages/Error404/Error404";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import SettingsPage from "./pages/SettingsPage/SettingsPage";

import './styles/global.css';

const ProtectedRoute = ({ children }) => {
  const { user, organization, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!organization) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  return <Layout>{children}</Layout>;
};

const AppRoutes = () => {
  const { user, organization, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/login" element={user ? <Navigate to={organization ? "/dashboard" : "/onboarding"} replace /> : <SignIn />} />
      <Route path="/signup" element={user ? <Navigate to="/onboarding" replace /> : <SignUp />} />

      <Route path="/onboarding" element={
        user 
          ? (!organization ? <Onboarding /> : <Navigate to="/dashboard" replace />)
          : <Navigate to="/login" replace />
      } />

      <Route path="/" element={<Navigate to={user ? (organization ? "/dashboard" : "/onboarding") : "/login"} replace />} />
      {user && organization ? (
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />  
          <Route path="/expense-invoices/*" element={<ExpenseInvoicesPage />} />
          <Route path="/expense-invoices/new" element={<ExpenseInvoicesPage isNewInvoice={true} />} />
          <Route path="/expense-invoices/:invoiceId" element={<ExpenseInvoicesPage />} />
          <Route path="/revenue-invoices/*" element={<RevenueInvoicesPage />} />
          <Route path="/revenue-invoices/new" element={<RevenueInvoicesPage isNewInvoice={true} />} />
          <Route path="/revenue-invoices/:invoiceId" element={<RevenueInvoicesPage />} />
          <Route path="/suppliers" element={<SuppliersPage />} />
          <Route path="/suppliers/new" element={<SuppliersPage isNewEntity={true} />} />
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/customers/new" element={<CustomersPage isNewEntity={true} />} />
          <Route path="/supplier-categories" element={<SupplierCategoriesPage />} />
          <Route path="/supplier-categories/new" element={<SupplierCategoriesPage isNewCategory={true} />} />
          <Route path="/customer-categories" element={<CustomerCategoriesPage />} />
          <Route path="/customer-categories/new" element={<CustomerCategoriesPage isNewCategory={true} />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}

export default App;
