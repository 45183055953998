import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./UploadInvoicesModal.css";
import uploadIcon from "../../assets/icons/File.svg"; // Adjust the path as needed
import uploadIcon2 from "../../assets/icons/UploadSimple.svg"; // Adjust the path as needed
import doneIcon from "../../assets/icons/CheckCircle.svg"; // Adjust the path as needed
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from "../../auth";
import config from '../../config';
import { createAnalyticsTrigger, addItemToAnalyticsTrigger, updateAnalyticsTrigger, pollAnalyticsTriggerStatus } from '../../utils/analyticsUtils';


const UploadInvoicesModal = ({ show, onClose, user, organization, storage, db, onInvoicesUpdated, invoiceType, fetchAllData, onAnalyticsUpdateStart, onAnalyticsUpdateEnd }) => {
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedOption, setSelectedOption] = useState(invoiceType === 'expense' ? 'expense' : 'revenue');
    const [uploadStatus, setUploadStatus] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentUploadingFile, setCurrentUploadingFile] = useState(null); // New state variable
    const uploadingFileRef = useRef(null); // Ref to track the currently uploading file element
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [allInvoicesProcessed, setAllInvoicesProcessed] = useState(false);
    const [updatingAnalytics, setUpdatingAnalytics] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { getIdToken } = useAuth();


    const navigate = useNavigate();
    const  BACKEND_API_URL = config.BACKEND_API_URL;    

    useEffect(() => {
        if (uploadingFileRef.current) {
            uploadingFileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [currentUploadingFile]);

    useEffect(() => {
        console.log("selectedOption changed to:", selectedOption);
      }, [selectedOption]);

      useEffect(() => {
        setSelectedOption(invoiceType);
    }, [invoiceType]);

    useEffect(() => {
        if (show) {
            setSelectedOption(invoiceType);  // Ensure the selected option is set correctly on open
        }
    }, [show, invoiceType]);


    useEffect(() => {
        if (updatingAnalytics && onAnalyticsUpdateStart) {
            onAnalyticsUpdateStart();
        } else if (!updatingAnalytics && onAnalyticsUpdateEnd) {
            onAnalyticsUpdateEnd();
        }
    }, [updatingAnalytics, onAnalyticsUpdateStart, onAnalyticsUpdateEnd]);


    const handleManualInvoiceClick = () => {
        onClose(); // Close the modal
        navigate(`/${invoiceType}-invoices/new`);
    };

    

    useEffect(() => {
        if (allInvoicesProcessed) {
            setAllInvoicesProcessed(false);
        }
    }, [allInvoicesProcessed, selectedOption]);

    if (!show) {
        return null;
    }

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget && !isUploading) {
            // Only close if not uploading
            resetState();
            onClose();
        }
    };

    const onCloseModal = () => {
        if (!isUploading) {
            // Only close if not uploading
            resetState();
            onClose();
        }
    };

    const resetState = () => {
        setFiles([]);
        setIsDragging(false);
        setSelectedOption('expense');
        setUploadStatus({});
        setUploadProgress(0);
        setCurrentUploadingFile(null);
        setIsUploading(false);
        setSelectedOption(invoiceType); // Reset to the current invoice type
        setUploadComplete(false);
        setShowSuccessMessage(false);
    };
    
    const handleFileInputChange = (e) => {
        const newFiles = Array.from(e.target.files);
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
        setUploadStatus((prevStatus) => {
            const newStatus = {};
            newFiles.forEach(file => {
                newStatus[file.name] = 'Pending';
            });
            return { ...prevStatus, ...newStatus };
        });
    };

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files);
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
        setUploadStatus((prevStatus) => {
            const newStatus = {};
            newFiles.forEach(file => {
                newStatus[file.name] = 'Pending';
            });
            return { ...prevStatus, ...newStatus };
        });
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (isDragging) {
            setIsDragging(false);
        }
    };

    const handleCheckboxChange = (option) => {
        console.log("Checkbox selected:", option);
        
        // make so you cannot select this when it's uploading
        if (isUploading) return;
      
        // Reset the upload status when changing the invoice type
        setUploadStatus({});
        setUploadProgress(0);
        setCurrentUploadingFile(null);
        setUploadComplete(false);
      
        // Reset the uploaded invoice IDs and processed invoices
        setAllInvoicesProcessed(false);
      
        // Set the new option
        setSelectedOption(option);
      
        // Reset files when changing invoice type
        setFiles([]);
      
        // Log the new option (this will still show the previous value due to closure)
        console.log("Selected option will be:", option);
      };

    
    const handleBulkUpload = async () => {
        if (isUploading || files.length === 0) return;
        setIsUploading(true);
        setUploadComplete(false);
        setShowSuccessMessage(false);
        setErrorMessage('');

        if (!user || !organization) {
            setIsUploading(false);
            setErrorMessage('User or organization data is missing.');
            return;
        }
    
        setUploadProgress(0);
    
        try {
            console.log("Starting bulk upload process");
            const token = await getIdToken();
            const triggerId = await createAnalyticsTrigger(db, organization, 'invoice', files.length, 'add', token);
    
            const filesArray = Array.from(files);
            const uploadedInvoices = [];
    
            for (let i = 0; i < filesArray.length; i++) {
                const file = filesArray[i];
                try {
                    console.log(`Processing file: ${file.name}`);
                    setCurrentUploadingFile(file);
    
                    setUploadStatus(prevStatus => ({
                        ...prevStatus,
                        [file.name]: 'Uploading...'
                    }));
    
                    const invoiceData = await uploadInvoice(file, selectedOption, triggerId);
                    uploadedInvoices.push(invoiceData);
    
                    console.log(`Adding invoice to analytics trigger: ${invoiceData.id}`);
                    await addItemToAnalyticsTrigger(db, triggerId, {
                        id: invoiceData.id,
                        type: 'invoice'
                    });
    
                    console.log(`Updating analytics trigger for invoice: ${invoiceData.id}`);
                    await updateAnalyticsTrigger(db, triggerId, invoiceData.id, 'pending');
    
                    setUploadStatus(prevStatus => ({
                        ...prevStatus,
                        [file.name]: 'Done'
                    }));
    
                    setUploadProgress(Math.round(((i + 1) / filesArray.length) * 100));
                } catch (error) {
                    console.error(`Error processing file ${file.name}:`, error);
                    setUploadStatus(prevStatus => ({
                        ...prevStatus,
                        [file.name]: 'Error'
                    }));
                }
            }
    
            console.log("All files processed, updating batch status");
            const batchRef = doc(db, 'analyticsTriggers', triggerId);
            await updateDoc(batchRef, {
                status_action: 'processing',
                updatedAt: serverTimestamp(),
                totalAffectedItems: uploadedInvoices.length
            });
    
            console.log("Sending batch to backend for processing");
            const response = await fetch(`${BACKEND_API_URL}/process-invoices-bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ 
                    invoices: uploadedInvoices,
                    invoiceType: selectedOption, 
                    triggerId,
                    organizationId: organization.id
                }),
            });
    
            const responseData = await response.json();
            console.log("Response from backend:", responseData);
    

            // Start polling for analytics trigger status
            // await pollAnalyticsTriggerStatus(triggerId);
            await pollAnalyticsTriggerStatus(
                triggerId,
                getIdToken,
                {
                    onActionCompleted: () => {
                        setIsUploading(false);
                        setUploadComplete(true);
                        setShowSuccessMessage(true);
                    },
                    onAnalyticsUpdateStart: () => {
                        setUpdatingAnalytics(true);
                        if (onAnalyticsUpdateStart) onAnalyticsUpdateStart();
                    },
                    onAnalyticsCompleted: async () => {
                        await fetchAllData();
                        setUpdatingAnalytics(false);
                        if (onAnalyticsUpdateEnd) onAnalyticsUpdateEnd();
                    },
                    onAnalyticsError: (error) => {
                        setUpdatingAnalytics(false);
                        setErrorMessage(`Analytics update failed: ${error}`);
                        if (onAnalyticsUpdateEnd) onAnalyticsUpdateEnd();
                    },
                    onPollingStart: () => {
                        console.log("Started polling for analytics trigger status");
                    },
                    onPollingEnd: (status) => {
                        console.log(`Polling ended with status: ${status}`);
                    }
                }
            );

            const uploadedInvoiceIds = uploadedInvoices.map(invoice => invoice.id);
            console.log("Uploaded invoice IDs:", uploadedInvoiceIds);
    
            // setUploadedInvoiceIds(uploadedInvoiceIds);
            onInvoicesUpdated(uploadedInvoiceIds, selectedOption);

        } catch (error) {
            console.error("Error in handleBulkUpload:", error);
            if (error.code === 'permission-denied') {
                console.error("Permission denied. User might not have the correct permissions.");
                setErrorMessage('Permission denied. Please check your account permissions.');
            } else {
                setErrorMessage(`An error occurred: ${error.message}`);
            }
            setIsUploading(false);
            setUploadComplete(false);
            setUpdatingAnalytics(false);
            setShowSuccessMessage(false);
        }
    };

    const onActionCompleted = () => {
        console.log("Action completed");
        setIsUploading(false);
        setUploadComplete(true);
        setShowSuccessMessage(true);
        if (onAnalyticsUpdateStart) {
            onAnalyticsUpdateStart();
        }
    };

    const onAnalyticsCompleted = () => {
        console.log("Analytics update completed");
        setUpdatingAnalytics(false);
        if (onAnalyticsUpdateEnd) {
            onAnalyticsUpdateEnd();
        }
    };

    const onAnalyticsError = (error) => {
        console.error("Analytics update failed:", error);
        setUpdatingAnalytics(false);
        setErrorMessage(`Analytics update failed: ${error}`);
        if (onAnalyticsUpdateEnd) {
            onAnalyticsUpdateEnd();
        }
    };

    const uploadInvoice = async (file, invoiceType, triggerId) => {
        console.log("Uploading file:", file.name, "Invoice type:", invoiceType);
        const userId = user.uid;
        const organizationId = organization.id;
    
        const invoiceRef = ref(storage, `${organizationId}/invoices/${file.name}`);
        const uploadTask = uploadBytesResumable(invoiceRef, file);
    
        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                () => { },
                (error) => {
                    console.error("Error uploading file:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        const gcsUri = `gs://${uploadTask.snapshot.ref.bucket}/${uploadTask.snapshot.ref.fullPath}`;
                
                        const invoiceDoc = await addDoc(collection(db, `organizations/${organizationId}/${invoiceType}Invoices`), {
                            userId,
                            fileName: file.name,
                            url,
                            gcsUri,
                            status: "new",
                            triggerId,
                            createdAt: serverTimestamp(),
                            updatedAt: serverTimestamp(),
                            // date_of_invoice: serverTimestamp(), // Add this line
                        });
                
                        resolve({
                            id: invoiceDoc.id,
                            type: 'invoice',
                            organizationId,
                            invoiceType,
                            fileName: file.name,
                            gcsUri,
                            url,
                            status: "new",
                            triggerId
                        });
                    } catch (error) {
                        console.error("Error in uploadInvoice:", error);
                        reject(error);
                    }
                }
            );
        });
    };


    const handleDoneClick = () => {
        resetState();
        onClose();

        // reset the state so next time you open it doesn' show done    
        setUploadComplete(false);

    };

    const renderFileList = () => {
        return files.map((file, index) => (
            <div 
                key={index} 
                className="file-item"
                ref={file.name === (currentUploadingFile && currentUploadingFile.name) ? uploadingFileRef : null}
            >
                <div className='file-upload-info'>
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                </div>
                <span className={`file-status ${uploadStatus[file.name] === 'Pending' ? 'pending' : ''} ${uploadStatus[file.name] === 'Uploading...' ? 'uploading' : ''} ${uploadStatus[file.name] === 'Done' ? 'done' : ''}`}>
                    {uploadStatus[file.name]}
                    {uploadStatus[file.name] === 'Done' && <img src={doneIcon} alt="Done" className="done-icon" />}
                </span>
            </div>
        ));
    };

    const renderMobileHeader = () => (
        <div className="mobile-header">
            <h2 className="modal-title">Upload {selectedOption} invoices</h2>
        </div>
    );  

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                {window.innerWidth <= 768 ? renderMobileHeader() : (
                    <button className="close-button" onClick={onCloseModal} disabled={isUploading}>×</button>
                )}
                <div className="modal-header">
                    {window.innerWidth > 768 && <h2 className="modal-title">Upload {selectedOption} invoices</h2>}
                <p className="modal-subtitle">
                        Upload your expenses and revenue invoices in bulk. You can also add your invoices manually <span className="link" onClick={handleManualInvoiceClick}>here</span>.
                </p>
                <div className="invoice-type-selector">
                    <button
                        className={`selector-button ${selectedOption === 'expense' ? 'active' : ''}`}
                        onClick={() => handleCheckboxChange('expense')}
                        disabled={isUploading}
                    >
                        Expenses
                    </button>
                    <button
                        className={`selector-button ${selectedOption === 'revenue' ? 'active' : ''}`}
                        onClick={() => handleCheckboxChange('revenue')}
                        disabled={isUploading}
                    >
                        Revenue
                    </button>
                    </div>
                </div>
                <div
                    className={`modal-body ${isDragging ? 'dragging' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                >
                    
                    <div className="upload-section">
                        {files.length === 0 ? (
                            <>
                                <div className="upload-icon">
                                    <img src={uploadIcon} alt="Upload icon" />
                                    <img className="upload-icon2" src={uploadIcon2} alt="Upload icon 2" />
                                </div>
                                <p className="upload-text">
                                    {window.innerWidth <= 768 ? (
                                        <>Tap to upload or<span className="link" onClick={handleChooseFileClick}>choose file</span></>
                                    ) : (
                                        <>Drag and Drop your files here or<span className="link" onClick={handleChooseFileClick}>choose file</span></>
                                    )}
                                </p>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileInputChange}
                                    multiple
                                />
                            </>
                        ) : (
                            <div className="file-list">
                                {renderFileList()}
                            </div>
                        )}
                    </div>
                    <div className="upload-footer">
                        <p className="supported-formats">Supported formats: PDS, JPG, PNG, HEIC</p>
                        <p className="max-size">Maximum size: 25mb per file</p>
                    </div>
                    {(isUploading || uploadComplete) && (
                        <div className="progress-section">
                            <div className="file-details">
                                <span className="file-name">
                                    {currentUploadingFile ? currentUploadingFile.name : 'Click Upload to start uploading'}
                                </span>
                                <span className="file-size">
                                    {currentUploadingFile ? `${(currentUploadingFile.size / 1024 / 1024).toFixed(2)} MB` : ''}
                                </span>
                            </div>
                            <div className="progress-bar-container">
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                                </div>
                                <span className="percentage">{uploadProgress.toFixed(0)}%</span>
                            </div>
                        </div>
                    )}
                    {showSuccessMessage && (
                        <div className="success-message">
                            Your invoices have been successfully processed. You can now close this modal.
                        </div>
                    )}
                    
                    {updatingAnalytics && (
                        <div className="analytics-update-message">
                            Analytics update in progress. We'll refresh the data once it's complete.
                        </div>
                )}
                </div>
                <div className="modal-footer">
                    <p className="file-count">{Object.values(uploadStatus).filter(status => status === 'Done').length} of {files.length} files</p>
                    <button className="cancel-button" onClick={onCloseModal} disabled={isUploading}>
                        {uploadComplete ? "Close" : "Cancel"}
                    </button>                    
                    <button 
                        className="upload-button" 
                        onClick={uploadComplete ? handleDoneClick : handleBulkUpload} 
                        disabled={isUploading || (files.length === 0 && !uploadComplete)}
                    >
                        {isUploading ? 'Uploading...' : (uploadComplete ? 'Done' : 'Upload')}
                    </button>
                </div>
            </div>
        </div>
    );
};

UploadInvoicesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    storage: PropTypes.object.isRequired,
    db: PropTypes.object.isRequired,
    onInvoicesUpdated: PropTypes.func.isRequired,
    invoiceType: PropTypes.oneOf(['expense', 'revenue']).isRequired,
    onAnalyticsUpdateStart: PropTypes.func, 
    onAnalyticsUpdateEnd: PropTypes.func,

};


export default UploadInvoicesModal;

