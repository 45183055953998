import { auth, googleProvider, db } from "./firebase";
import { signInWithPopup , onAuthStateChanged} from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from 'firebase/auth';



export const handleGoogleSignIn = async (userType) => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const newUser = result.user;

    console.log("Signed in user:", newUser);

    // Check if user document exists in Firestore
    const userRef = doc(db, "users", newUser.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      console.log("User document does not exist, creating new document");
      await setDoc(userRef, {
        email: newUser.email,
        'user-type': userType,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }

    return newUser;
  } catch (error) {
    console.error("Error with Google sign-in:", error);
    throw error;
  }
};


export const listenAuthState = (setUser, setOrganization) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({ ...user, ...userData });

        if (userData.organizationId) {
          const orgDoc = await getDoc(doc(db, "organizations", userData.organizationId));
          if (orgDoc.exists()) {
            setOrganization({ id: orgDoc.id, ...orgDoc.data() });
          }
        } else {
          setOrganization(null);
        }
      } else {
        setUser(user);
        setOrganization(null);
      }
    } else {
      setUser(null);
      setOrganization(null);
    }
  });
};


export const getIdToken = async () => {
  const auth = getAuth();
  const firebaseUser = auth.currentUser;
  if (firebaseUser) {
    return await firebaseUser.getIdToken();
  } else {
    throw new Error('No authenticated user found');
  }
};
