import React, { useState, useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import './Layout.css';

const Layout = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadInvoiceType, setUploadInvoiceType] = useState('expense');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleOpenUploader = useCallback((type) => {
    setUploadInvoiceType(type);
    setShowUploadModal(true);
  }, []);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
    console.log('Sidebar toggled, new state:', !isSidebarOpen); // Add this line for debugging
  };

  return (
    <div className="layout">
      <Header onLogout={handleLogout} toggleSidebar={toggleSidebar} />
      <div className="layout__main">
        <Sidebar 
          className={`layout__sidebar ${isSidebarOpen ? 'open' : ''}`} 
          onOpenUploader={handleOpenUploader}
          onClose={() => setIsSidebarOpen(false)}
          isMobile={isMobile}
        />
        <div className="layout__content">
          <Outlet context={{ showUploadModal, setShowUploadModal, uploadInvoiceType, handleOpenUploader }} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
