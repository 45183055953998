import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
import logoIcon from '../../assets/logo.svg'; // Adjust the path as needed
import searchIcon from '../../assets/icons/Search.svg'; // Adjust the path as needed
import notificationIcon from '../../assets/icons/Bell.svg'; // Adjust the path as needed
import profileIcon from '../../assets/icons/Question.svg'; // Adjust the path as needed
import burgerIcon from '../../assets/icons/List.svg'; // Add this import

const Header = ({ toggleSidebar }) => {
  return (
    <div className="header">
      <div className="header__left">
        <Link to="/dashboard">
          <img src={logoIcon} alt="Logo" className="header__logo" />
        </Link>
      </div>
      {/* <div className="header__middle">
        <div className="header__search">
          <img src={searchIcon} alt="Search" className="header__search-icon" />
          <input type="text" placeholder="⌘ + K to search a query" />
        </div>
      </div> */}
      <div className="header__right">
        <img src={notificationIcon} alt="Notifications" className="header__icon" />
        <img src={profileIcon} alt="Profile" className="header__icon" />
        <img 
          src={burgerIcon} 
          alt="Menu" 
          className="header__icon header__burger-icon" 
          onClick={toggleSidebar}
        />
      </div>
    </div>
  );
};

export default Header;


