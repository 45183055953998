import { useState, useEffect, createContext, useContext } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getIdToken as getIdTokenUtil } from './authUtils';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

  const [authState, setAuthState] = useState({
    user: null,
    organization: null,
    loading: true
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let organizationData = null;
            if (userData.organizationId) {
              try {
                const orgDoc = await getDoc(doc(db, 'organizations', userData.organizationId));
                if (orgDoc.exists()) {
                  const orgData = orgDoc.data();
                  if (orgData.users && orgData.users[firebaseUser.uid]) {
                    organizationData = { id: userData.organizationId, ...orgData };
                  }
                }
              } catch (orgError) {
                console.error("Error fetching organization data:", orgError);
              }
            }
            setAuthState({
              user: { ...firebaseUser, ...userData },
              organization: organizationData,
              loading: false
            });
          } else {
            setAuthState({
              user: firebaseUser,
              organization: null,
              loading: false
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setAuthState({
            user: firebaseUser,
            organization: null,
            loading: false
          });
        }
      } else {
        setAuthState({
          user: null,
          organization: null,
          loading: false
        });
      }
    });
  
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const getIdToken = async () => {
    if (authState.user) {
      return await getIdTokenUtil();
    } else {
      throw new Error('No authenticated user found');
    }
  };

  

  const value = {
    ...authState,
    setAuthState,
    logout,
    getIdToken
  };

  return (
    <AuthContext.Provider value={value}>
      {!authState.loading && children}
    </AuthContext.Provider>
  );
};

