import React, { useMemo, useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

import './ChartComponent.css';

const ChartComponent = ({ 
    data = {}, 
    type = '', 
    startDate = '', 
    endDate = '',
    categories = [],
    entities = [],
    onFilterChange
}) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [dateRange, setDateRange] = useState({ start: startDate, end: endDate });
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    console.log("Data received in ChartComponent:", data);  // Log data received in ChartComponent
    console.log("Type received in ChartComponent:", type);  // Log type received in ChartComponent

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    // Effect to set all categories and entities as selected by default
    useEffect(() => {
        setSelectedCategories(categories.map(category => category.id));
        setSelectedEntities(entities.map(entity => entity.id));
    }, [categories, entities]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const toggleDropdown = (dropdownName) => {
        if (!isLoading) {
            setActiveDropdown(prevDropdown => prevDropdown === dropdownName ? null : dropdownName);
        }
    };

    const handleCategoryChange = (categoryId) => {
        if (!isLoading) {
            setSelectedCategories(prev => 
                prev.includes(categoryId) 
                    ? prev.filter(id => id !== categoryId)
                    : [...prev, categoryId]
            );
        }
    };

    const handleEntityChange = (entityId) => {
        if (!isLoading) {
            setSelectedEntities(prev => 
                prev.includes(entityId) 
                    ? prev.filter(id => id !== entityId)
                    : [...prev, entityId]
            );
        }
    };

    const handleDateChange = (event) => {
        if (!isLoading) {
            setDateRange(prev => ({ ...prev, [event.target.name]: event.target.value }));
        }
    };


    const handleApplyFilters = async () => {
        setIsLoading(true);
        setActiveDropdown(null); // Close any open dropdown
        try {
            await onFilterChange({
                start: dateRange.start,
                end: dateRange.end,
                categories: selectedCategories,
                entities: selectedEntities
            });
        } catch (error) {
            console.error("Error applying filters:", error);
        } finally {
            setIsLoading(false);
        }
    };
   
    const generateAllMonths = (start, end) => {
        console.log("Start date:", start, "End date:", end);
        const startDate = new Date(start);
        const endDate = new Date(end);
        const allMonths = [];
    
        while (startDate <= endDate) {
            const currentMonth = startDate.toISOString().slice(0, 7);
            if (!allMonths.includes(currentMonth)) {
                allMonths.push(currentMonth);
            }
            startDate.setMonth(startDate.getMonth() + 1);
        }

        // Add the last month if it's not already included
        const lastMonth = endDate.toISOString().slice(0, 7);
        if (allMonths[allMonths.length - 1] !== lastMonth) {
            allMonths.push(lastMonth);
        }

        console.log("All months:", allMonths);
    
        return allMonths;
    };


    

    const mergedData = useMemo(() => {
        const dataDates = Object.keys(data);
        console.log(`${type} data dates:`, dataDates);

        if (dataDates.length === 0) {
            // Generate empty data for the last 12 months
            const emptyData = [];
            const end = new Date();
            const start = new Date(end.getFullYear(), end.getMonth() - 11, 1);
            
            while (start <= end) {
                emptyData.push({
                    month: start.toISOString().slice(0, 7),
                    total: 0,
                    subjectToVat: 0,
                    notSubjectToVat: 0,
                    vatTax: 0,
                });
                start.setMonth(start.getMonth() + 1);
            }
            return emptyData;
        }

        const firstDataDate = dataDates.reduce((a, b) => a < b ? a : b);
        const lastDataDate = dataDates.reduce((a, b) => a > b ? a : b);

        console.log(`${type} first date:`, firstDataDate, "last date:", lastDataDate);

        const effectiveStartDate = startDate ? 
            (firstDataDate < startDate ? startDate : firstDataDate) : 
            firstDataDate;
        const effectiveEndDate = endDate ? 
            (lastDataDate > endDate ? endDate : lastDataDate) : 
            lastDataDate;

        console.log(`${type} effective start date:`, effectiveStartDate, "effective end date:", effectiveEndDate);


        const allMonths = generateAllMonths(effectiveStartDate, effectiveEndDate);
        console.log(`${type} all months:`, allMonths);


        const result = allMonths.map(month => ({
            month,
            total: data[month] ? (type === 'expense' ? data[month].totalExpenses : data[month].totalRevenue) : 0,
            subjectToVat: data[month] ? data[month].totalSubjectToVatTax : 0,
            notSubjectToVat: data[month] ? data[month].totalNotSubjectToVatTax : 0,
            vatTax: data[month] ? data[month].totalVatTax : 0,
        }));

        console.log(`${type} merged data:`, result);
        return result;

    }, [data, type, startDate, endDate]);

  

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Month: ${label}`}</p>
                    <p className="total">{`Total: €${payload[0].value.toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };

    if (mergedData.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div className="chart-component">
            <div className="chart-header">
                 <h2>{type === 'expense' ? 'Expenses' : 'Revenue'} Overview</h2>
            </div>      
            <div className="chart-filters" ref={dropdownRef}>
                <div className="chart-filter-dropdown">
                    <button 
                        className="chart-filter-button" 
                        onClick={() => toggleDropdown('category')}
                        disabled={isLoading}
                    >
                        Categories ({selectedCategories.length})
                    </button>
                    {activeDropdown === 'category' && (
                        <div className="chart-dropdown-content">
                            {categories.map((category) => (
                                <div 
                                    key={category.id} 
                                    className={`chart-dropdown-item ${isLoading ? 'disabled' : ''}`}
                                    onClick={() => handleCategoryChange(category.id)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category.id)}
                                        readOnly
                                        disabled={isLoading}
                                    />
                                    {category.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>


                <div className="chart-filter-dropdown">
                    <button 
                        className="chart-filter-button" 
                        onClick={() => toggleDropdown('entity')}
                        disabled={isLoading}
                    >
                        {type === 'expense' ? 'Suppliers' : 'Customers'} ({selectedEntities.length})
                    </button>
                    {activeDropdown === 'entity' && (
                        <div className="chart-dropdown-content">
                            {entities
                                .filter(entity => entity && entity.id && entity.name) // Filter out invalid entities
                                .map((entity) => (
                                    <div 
                                        key={entity.id} 
                                        className={`chart-dropdown-item ${isLoading ? 'disabled' : ''}`}
                                        onClick={() => handleEntityChange(entity.id)}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedEntities.includes(entity.id)}
                                            readOnly
                                            disabled={isLoading}
                                        />
                                        {entity.name}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>

                <div className="chart-date-inputs">
                    
                    <input
                        type="date"
                        name="start"
                        value={dateRange.start}
                        onChange={handleDateChange}
                        className="chart-filter-button"
                        disabled={isLoading}
                    />

                    <input
                        type="date"
                        name="end"
                        value={dateRange.end}
                        onChange={handleDateChange}
                        className="chart-filter-button"
                        disabled={isLoading}
                    />
                </div>

                <button 
                    className={`chart-apply-button ${isLoading ? 'loading' : ''}`} 
                    onClick={handleApplyFilters}
                    disabled={isLoading}
                >
                    {isLoading ? 'Applying...' : 'Apply Filters'}
                </button>
            </div>

            <ResponsiveContainer width="100%" aspect={isMobile ? 1.5 : 2}>
                <BarChart
                    data={mergedData}
                    margin={isMobile ? { top: 10, right: 0, left: 0, bottom: 0 } : { top: 20, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap="20%"
                    barGap="5%"
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ECECEC" />
                    <XAxis 
                        dataKey="month" 
                        stroke="#ECECEC" 
                        tick={{ fill: '#A4A4A4', fontSize: isMobile ? 10 : 14 }}
                        angle={isMobile ? -45 : 0}
                        textAnchor={isMobile ? 'end' : 'middle'}
                        height={isMobile ? 50 : 30}
                        tickMargin={isMobile ? 5 : 0}
                    />
                    <YAxis 
                        stroke="#fff" 
                        tick={{ fill: '#A4A4A4', fontSize: isMobile ? 10 : 14 }}
                        tickCount={isMobile ? 3 : 5}
                        angle={isMobile ? -4 : 0}
                        textAnchor={isMobile ? 'start' : 'middle'}
                        width={isMobile ? 20 : 40}
                        interval={0}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={isMobile ? { fontSize: '10px', marginTop: '-10px' } : undefined} />
                    <Bar 
                        dataKey="total" 
                        name="Total" 
                        fill={type === 'expense' ? "#EE7C30" : "#009A6B"} 
                        radius={[4, 4, 0, 0]} 
                        barSize={isMobile ? 12 : 25}
                    />
                </BarChart>
            </ResponsiveContainer>

            {/* <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={mergedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap="20%"
                    barGap="5%"
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ECECEC" />
                    <XAxis dataKey="month" stroke="#ECECEC" tick={{ fill: '#A4A4A4' }} />
                    <YAxis stroke="#fff" tick={{ fill: '#A4A4A4' }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar 
                        dataKey="total" 
                        name="Total" 
                        fill={type === 'expense' ? "#EE7C30" : "#009A6B"} 
                        stackId="a" 
                        radius={[8, 8, 0, 0]} 
                    />
                </BarChart>
            </ResponsiveContainer> */}
            {mergedData.every(item => item.total === 0) && (
                <div className="no-data-overlay">
                    <p>No {type} data available for the selected period</p>
                </div>
            )}
        </div>
    );
};

ChartComponent.propTypes = {
    data: PropTypes.object,
    type: PropTypes.oneOf(['expense', 'revenue']),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string
    })),
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })),
    onFilterChange: PropTypes.func,
};


export default ChartComponent;
